export default {
  en: "English",
  nl: "Dutch",
};

export const localeStrings = {
  en: "en-US",
  nl: "nl-NL",
};

export const localeStringDefault = localeStrings.en;
